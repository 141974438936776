import { Directive, EventEmitter, HostListener, Output } from '@angular/core'

@Directive({
  selector: '[appOnlyNumbers]',
  standalone: true,
})
export class OnlyNumbersDirective {
  @Output() valueChange = new EventEmitter<string>();

  @HostListener('input', ['$event'])
  onInputChange(event: InputEvent): void {
    const input = event.target as HTMLInputElement;
    const sanitizedValue: string = input.value.replace(/[^0-9]/g, '');

    input.value = sanitizedValue;

    this.valueChange.emit(sanitizedValue);
  }
}
